import FLAMES_ROWS from "../constants/FlamesSeatRows";
import WRANGLERS_ROWS from "../constants/WranglersSeatRows";
import JETS_ROWS from "../constants/JetsSeatRows";
import MOOSE_ROWS from "../constants/MooseSeatRows";
import MAPLE_LEAFS_ROWS from "../constants/MapleLeafsSeatRows";
import OILERS_ROWS from "../constants/OilersSeatRows";
import SENATORS_ROWS from "../constants/SenatorsSeatRows";
import RAPTORS_ROWS from "../constants/RaptorsSeatRows";
import CANUCKS_ROWS from "../constants/CanucksSeatRows";
import BLUE_JAYS_ROWS from "../constants/BlueJaysSeatRows";
import ELKS_ROWS from "../constants/ElksSeatRows";
import BLUE_BOMBERS_ROWS from "../constants/BlueBombersSeatRows";
import ARGONAUTS_ROWS from "../constants/ArgonautsSeatRows";
import TORONTOFCS_ROWS from "../constants/TorontoFcsSeatRows";
import STAMPEDERS_ROWS from "../constants/StampedersSeatRows";
import REDBLACKS_ROWS from "../constants/RedblacksSeatRows";
import LIONS_ROWS from "../constants/LionsSeatRows";
import WHITECAPS_ROWS from "../constants/WhitecapsSeatRows";
import ROUHGRIDERS_ROWS from "../constants/RoughridersSeatRows";
import TIGERCATS_ROWS from "../constants/TigerCatsSeatRows";
import LIONS_SPECIAL_ROWS from "../constants/LionsSpecialSeatRows";
import ABBOTSFORD_CANUCKS_ROWS from "../constants/AbbotsfordCanucksSeatRows";
import BELLEVILLE_ROWS from "../constants/BellevilleSeatRows";
import MARLIES_ROWS from "../constants/MarliesSeatRows";
import ROUGHNECKS_ROWS from "../constants/RoughnecksSeatRows";

const oilersRows = (section) => {
  if (section === "Sky Lounge AA") {
    return OILERS_ROWS.SKY_LOUNGE_AA_ROWS;
  } else if (section === "Sky Lounge A") {
    return OILERS_ROWS.SKY_LOUNGE_A_ROWS;
  } else if (section === "Sky Lounge B") {
    return OILERS_ROWS.SKY_LOUNGE_B_ROWS;
  } else if (section === "Sky Lounge C") {
    return OILERS_ROWS.SKY_LOUNGE_C_ROWS;
  } else if (section === "Sky Lounge D") {
    return OILERS_ROWS.SKY_LOUNGE_D_ROWS;
  } else if (section === "Sky Lounge E") {
    return OILERS_ROWS.SKY_LOUNGE_E_ROWS;
  } else if (section === "Sportsnet Club A") {
    return OILERS_ROWS.SPORTSNET_A_ROWS;
  } else if (section === "Sportsnet Club B") {
    return OILERS_ROWS.SPORTSNET_B_ROWS;
  } else if (section === "Sportsnet Club C") {
    return OILERS_ROWS.SPORTSNET_C_ROWS;
  } else if (section === "Sportsnet Club D") {
    return OILERS_ROWS.SPORTSNET_D_ROWS;
  } else if (section === "Sportsnet Club E") {
    return OILERS_ROWS.SPORTSNET_E_ROWS;
  } else if (section && section.includes("Sky Lounge")) {
    return OILERS_ROWS.SKY_LOUNGE_ROWS;
  } else if (section && section.includes("Sportsnet Club")) {
    return OILERS_ROWS.SPORTSNET_ROWS;
  } else if (section && section.includes("Loge")) {
    return OILERS_ROWS.LOGE_ROWS;
  } else if (["Lower Bowl 107", "Lower Bowl 109", "Lower Bowl 113", "Lower Bowl 115"].includes(section)) {
    return OILERS_ROWS.OZONE_CLUB_ROWS;
  } else {
    return OILERS_ROWS.DEFAULT_ROWS;
  }
};

const jetsRows = (section) => {
  if (section.includes("PREMIUM SUITE LOUNGE")) {
    return JETS_ROWS.PREMIUM_ROWS;
  } else if (section.includes("Loge")) {
    return JETS_ROWS.LOGE_ROWS;
  } else if (["Lower Zone 125", "Lower Zone 126"].includes(section)) {
    return JETS_ROWS.BAR_ROWS;
  } else {
    return JETS_ROWS.NON_LOGE_ROWS;
  }
};

const mooseRows = (section) => {
  if (section.includes("PREMIUM SUITE LOUNGE")) {
    return MOOSE_ROWS.PREMIUM_ROWS;
  } else if (section.includes("Loge")) {
    return MOOSE_ROWS.LOGE_ROWS;
  } else {
    return MOOSE_ROWS.NON_LOGE_ROWS;
  }
};

const mapleLeafsRows = (section) => {
  if (section.includes("Upper")) {
    return MAPLE_LEAFS_ROWS.UPPER_ROWS;
  } else if (section.includes("Molson") || section.includes("GBOX") || section.includes("Suite")) {
    return MAPLE_LEAFS_ROWS.SINGLE_ROW;
  } else if (section === "Lower Zone 112" || section === "Lower Zone 113" || section === "Lower Zone 114" || 
  section === "Lower Zone 115" || section === "Lower Zone 116" || section === "Lower Zone 101" || section === "Lower Zone 102" || 
  section === "Lower Zone 103" || section === "Lower Zone 104") {
    return MAPLE_LEAFS_ROWS.NUMBER_AND_LETTER_ROWS;
  } else {
    return MAPLE_LEAFS_ROWS.DEFAULT_ROWS;
  }
};

const senatorsRows = (section) => {
  const wcsRow = [
    {
      value: "WCS",
      label: `Row WCS`,
    },
    {
      value: "STN",
      label: `Row STN`,
    },
  ];
  if (section === "Lower Zone 116"){
    return [...SENATORS_ROWS.C_TO_S, ...wcsRow]
  } else if (section.includes("Lower Zone") || section.includes("Upper Zone")) {
    return [...SENATORS_ROWS.A_TO_S, ...wcsRow]
  } else {
    return [...SENATORS_ROWS.A_TO_F, ...wcsRow]
  }
};

const raptorsRows = (section) => {
  if (section.includes("Courtside")) {
    return RAPTORS_ROWS.COURTSIDE_ROWS
  } else if (section.includes("Molson") || section.includes("GBOX") || section.includes("Suite")) {
    return RAPTORS_ROWS.SINGLE_ROW;
  } else if (section.includes("Lower Zone")) {
      return RAPTORS_ROWS.LOWER_ZONE_ROWS;
  } else {
    return RAPTORS_ROWS.DEFAULT_ROWS;
  }
}

const canucksRows = (section) => {
  if (section.includes("WC")) {
    return CANUCKS_ROWS.WC_ROWS;
  } else {
    return CANUCKS_ROWS.DEFAULT_ROWS;
  }
};

const blueJaysRows = (section) => {
  const sectionNo = section.split(" ").pop();

  if (["1", "5"].includes(sectionNo)) {
    return BLUE_JAYS_ROWS.generateRows(1, 5, "WC");
  } else if (["2", "3", "4"].includes(sectionNo)) {
    return BLUE_JAYS_ROWS.generateRows(1, 5);
  } else if (["16", "17", "18", "19", "29", "30", "31", "32", "242", "243"].includes(sectionNo)) {
    return BLUE_JAYS_ROWS.generateRows(1, 11);
  } else if (["20", "21", "22", "23", "24", "25", "26", "27", "28"].includes(sectionNo)) {
    return BLUE_JAYS_ROWS.generateRows(1, 8);
  } else if (sectionNo === "108") {
    return BLUE_JAYS_ROWS.generateRows(22, 36, "WC");
  } else if (sectionNo === "109") {
    return BLUE_JAYS_ROWS.generateRows(21, 39, "WC");
  } else if (["110", "111", "112"].includes(sectionNo)) {
    return BLUE_JAYS_ROWS.generateRows(7, 39, "WC");
  } else if (["113", "114", "115", "134", "135"].includes(sectionNo)) {
    return BLUE_JAYS_ROWS.generateRows(1, 36, "WC");
  } else if (["116", "132"].includes(sectionNo)) {
    return BLUE_JAYS_ROWS.generateRows(7, 40, "41D");
  } else if (["117", "121", "127", "131"].includes(sectionNo)) {
    return BLUE_JAYS_ROWS.generateRows(1, 16, "WC");
  } else if (["118", "119", "129", "130"].includes(sectionNo)) {
    return BLUE_JAYS_ROWS.generateRows(1, 20, "21D");
  } else if (["120", "128"].includes(sectionNo)) {
    return BLUE_JAYS_ROWS.generateRows(1, 19, "20D");
  } else if (["122", "126"].includes(sectionNo)) {
    return BLUE_JAYS_ROWS.generateRows(1, 15, "WC");
  } else if (["123", "124", "125"].includes(sectionNo)) {
    return BLUE_JAYS_ROWS.generateRows(1, 14, "WC");
  } else if (sectionNo === "133") {
    return BLUE_JAYS_ROWS.generateRows(1, 40, "41D");
  } else if (["136", "137"].includes(sectionNo)) {
    return BLUE_JAYS_ROWS.generateRows(1, 39, "WC");
  } else if (sectionNo === "138") {
    return BLUE_JAYS_ROWS.generateRows(2, 39, "WC");
  } else if (sectionNo === "139") {
    return BLUE_JAYS_ROWS.generateRows(12, 39, "WC");
  } else if (sectionNo === "140") {
    return BLUE_JAYS_ROWS.generateRows(22, 39);
  } else if (sectionNo === "141") {
    return BLUE_JAYS_ROWS.generateRows(26, 32, "WC");
  } else if (["142", "143", "239", "240", "241"].includes(sectionNo)) {
    return BLUE_JAYS_ROWS.generateRows(1, 9);
  } else if (["Lower Zone B 144", "Lower Zone B 145", "Lower Zone B 146"].includes(section)) {
    return BLUE_JAYS_ROWS.generateRows(1, 9);
  } else if (["Lower Zone A 144", "Lower Zone A 145", "Middle Zone 244"].includes(section)) {
    return BLUE_JAYS_ROWS.generateRows(1, 6);
  } else if (section === "Lower Zone A 146") {
    return BLUE_JAYS_ROWS.generateRows(1, 4);
  } else if (section === "Lower Zone A 147") {
    return BLUE_JAYS_ROWS.generateRows(1, 3);
  } else if (section === "Lower Zone B 147") {
    return BLUE_JAYS_ROWS.generateRows(1, 7, "WC");
  } else if (section === "Lower Zone A 148") {
    return BLUE_JAYS_ROWS.generateRows(1, 2);
  } else if (section === "Lower Zone B 148") {
    return BLUE_JAYS_ROWS.generateRows(1, 7);
  } else if (["CORONA ROOFTOP PATIO", "LEFT FIELD BALCONY", "RIGHT FIELD BLEACHERS", "ROGERS LANDING", "SCHNEIDERS PORCH", "TD PARK SOCIAL", "THE CATCH BAR", "THE STOP"].includes(section)) {
    return BLUE_JAYS_ROWS.GA_ROW;
  } else if (section.includes("Courtside")) {
    return BLUE_JAYS_ROWS.COURTSIDE_ROWS
  } else if (["TM LOUNGE", "HOME RUN ZONE"].includes(section)) {
    return BLUE_JAYS_ROWS.SINGLE_ROW;
  } else if (["W111L", "W111R"].includes(sectionNo)) {
    return BLUE_JAYS_ROWS.F_ROW;
  } else if (["224", "225", "226", "227", "233"].includes(sectionNo)) {
    return BLUE_JAYS_ROWS.generateRows(1, 10);
  } else if (["228", "229", "230", "231", "232", "234", "235", "236", "237", "238"].includes(sectionNo)) {
    return BLUE_JAYS_ROWS.generateRows(1, 13);
  } else if (section.includes("WESTJET FLIGHT DECK")) {
    return BLUE_JAYS_ROWS.WESTJETFLIGHTDECK_ROW;
  } else if (section.includes("Lower Zone")) {
      return BLUE_JAYS_ROWS.LOWER_ZONE_ROWS;
  } else {
    return BLUE_JAYS_ROWS.DEFAULT_ROWS;
  }
}

const elksRows = (section) => {
  if (section.includes("Twisted Tea Party Deck")) {
    return ELKS_ROWS.TTPD_ROW;
  } else if (section.includes("Coors Light Party Deck")) {
    return ELKS_ROWS.CLPD_ROW;
  } else if (section.includes("Rooftop Patio")) {
    return ELKS_ROWS.RP_ROWS;
  } else if (section.includes("Upper Zone")) {
    return ELKS_ROWS.UPPER_ZONE_ROWS;
  } else if (section.includes("Lower Zone")) {
    return ELKS_ROWS.LOWER_ZONE_ROWS;
  } else if (section.includes("ENDZONE")) {
    return ELKS_ROWS.ENDZONE_ROWS;
  } else {
    return ELKS_ROWS.DEFAULT_ROWS;
  }
}

const blueBombersRows = (section) => {
  const sectionNo = section.split(" ").pop();
  if (section.includes("Jim Beam Social")) {
    return BLUE_BOMBERS_ROWS.JIM_BEAM_SOCIAL;
  } else if (section.includes("Upper Zone")) {
    return BLUE_BOMBERS_ROWS.UPPER_ZONE_ROWS;
  } else if (section.includes("Lower Zone")) {
    if (["106", "107", "108", "109"].includes(sectionNo)) {
      return BLUE_BOMBERS_ROWS.PINNACLE_CLUB_ROWS;
    }
    return BLUE_BOMBERS_ROWS.LOWER_ZONE_ROWS;
  } else {
    return BLUE_BOMBERS_ROWS.DEFAULT_ROWS;
  }
}

const argonautsRows = (section) => {
  if (section.includes("Side Seats")) {
    return ARGONAUTS_ROWS.SIDE_SEATS_ROWS;
  } else if (section.includes("Upper Zone")) {
    return ARGONAUTS_ROWS.UPPER_ZONE_ROWS;
  } else if (section.includes("Lower Zone")) {
      return ARGONAUTS_ROWS.LOWER_ZONE_ROWS;
  } else {
    return ARGONAUTS_ROWS.DEFAULT_ROWS;
  }
}

const torontofcsRows = (section) => {
  if (section.includes("Side Seats")) {
    return TORONTOFCS_ROWS.SIDE_SEATS_ROWS;
  } else if (section.includes("Upper Zone")) {
    return TORONTOFCS_ROWS.UPPER_ZONE_ROWS;
  } else if (section.includes("Lower Zone")) {
    return TORONTOFCS_ROWS.LOWER_ZONE_ROWS;
  } else {
    return TORONTOFCS_ROWS.DEFAULT_ROWS;
  }
}

const stampedersRows = (section) => {
  if (section.includes("CLUBHOUSE")) {
    return STAMPEDERS_ROWS.CLUBHOUSE_ROWS;
  } else if (section.includes("Lower Zone")) {
      return STAMPEDERS_ROWS.LOWER_ZONE_ROWS;
  } else {
    return STAMPEDERS_ROWS.DEFAULT_ROWS;
  }
}

const redblacksRows = (section) => {
  if (section.includes("Field")) {
    return REDBLACKS_ROWS.DEFAULT_ROWS;
  } else if (section.includes("Upper Zone")) {
    return REDBLACKS_ROWS.UPPER_ZONE_ROWS;
  } else if (section.includes("Middle Zone")) {
      return REDBLACKS_ROWS.MIDDLE_ZONE_ROWS;
  } else if (section.includes("Lower Zone")) {
    return REDBLACKS_ROWS.LOWER_ZONE_ROWS;
  } else {
    return REDBLACKS_ROWS.DEFAULT_ROWS;
  }
}

const lionsRows = (section) => {
  if (section.includes("The Den")) {
    return LIONS_ROWS.THE_DEN_ROWS;
  } else if (section.includes("Upper Zone")) {
    return LIONS_ROWS.UPPER_ZONE_ROWS;
  } else if (section.includes("Middle Zone")) {
      return LIONS_ROWS.MIDDLE_ZONE_ROWS;
  } else if (section.includes("Lower Zone")) {
    return LIONS_ROWS.LOWER_ZONE_ROWS;
  } else {
    return LIONS_ROWS.DEFAULT_ROWS;
  }
}

const whitecapsRows = (section) => {
  if (section.includes("General Admission")) {
    return WHITECAPS_ROWS.GENERAL_ADMISSION;
  } else if (section.includes("Middle Zone")) {
    return WHITECAPS_ROWS.MIDDLE_ZONE_ROWS;
  } else if (section.includes("Lower Zone")) {
    return WHITECAPS_ROWS.LOWER_ZONE_ROWS;
  } else {
    return WHITECAPS_ROWS.DEFAULT_ROWS;
  }
}

const roughridersRows = (section) => {
  if (section.includes("Upper Zone")) {
    return ROUHGRIDERS_ROWS.UPPER_ZONE_ROWS;
  } else if (section.includes("Middle Zone")) {
      return ROUHGRIDERS_ROWS.MIDDLE_ZONE_ROWS;
  } else if (section.includes("Lower Zone")) {
    return ROUHGRIDERS_ROWS.LOWER_ZONE_ROWS;
  } else if (section.includes("PIL COUNTRY")) {
    return ROUHGRIDERS_ROWS.PIL_COUNTRY_ROWS;
  } else {
    return ROUHGRIDERS_ROWS.DEFAULT_ROWS;
  }
}

const tigercatsRows = (section) => {
  const sectionNo = section.split(" ").pop();
  if (["The Striply", "Stelco Northend"].includes(section)) {
    return TIGERCATS_ROWS.OTHER_ROWS;
  } else if (section.includes("Touchdown Lounge")) {
    return TIGERCATS_ROWS.TOUCHDOWN_LOUNGE_ROWS;
  } else if (["Lower Zone 111", "Lower Zone 119"].includes(section)) {
    // Lower Zone 111 and 119
    return TIGERCATS_ROWS.LOWER_ZONE_OTHER_ROWS;
  } else if (section.includes("Lower Zone")) {
    // Lower Zone 101 - 109
    // Lower Zone 112 - 118
    return TIGERCATS_ROWS.LOWER_ZONE_ROWS;
  } else if (["C1", "C11"].includes(sectionNo)) {
    // Middle Zone C1 and C11
    return TIGERCATS_ROWS.PREMIUM_ZONE_ROWS1;
  } else if (["C2", "C3", "C4", "C5", "C6", "C7", "C8", "C9", "C10"].includes(sectionNo)) {
    // Middle Zone C2 - C10
    return TIGERCATS_ROWS.PREMIUM_ZONE_ROWS2;
  } else if (["202", "203", "204", "205", "206", "207", "208"].includes(sectionNo)) {
    // Upper Zone 202 - 208
    return TIGERCATS_ROWS.UPPER_ZONE_ROWS1;
  } else if (["212", "213", "214", "215", "216", "217", "218"].includes(sectionNo)) {
    // Upper Zone 212 - 218
    return TIGERCATS_ROWS.UPPER_ZONE_ROWS2;
  } else {
    return TIGERCATS_ROWS.DEFAULT_ROWS;
  }
}

const lionsSpecialRows = (section) => {
  const sectionNo = section.split(" ").pop();

  if (sectionNo === "1") {
    return LIONS_SPECIAL_ROWS.generateAlphabetRows(["A", "B", "C", "D", "E"]);
  } else if (sectionNo === "2") {
    return LIONS_SPECIAL_ROWS.generateAlphabetRows(["A", "B", "C", "D", "E","F"]);
  } else if (["3", "5"].includes(sectionNo)) {
    return LIONS_SPECIAL_ROWS.generateAlphabetRows(["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O"]);
  } else if (["6", "7", "8", "9", "11", "12"].includes(sectionNo)) {
    return LIONS_SPECIAL_ROWS.generateAlphabetRows(["A", "B", "C", "D", "E", "F", "G", "H"]);
  } else if (["4", "10"].includes(sectionNo)) {
    return LIONS_SPECIAL_ROWS.generateAlphabetRows(["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N"]);
  } else if (["13", "14", "15", "16", "17", "18", "19", "20", "21"].includes(sectionNo)) {
    return LIONS_SPECIAL_ROWS.DEFAULT_ROWS;
  } else if (["7B", "8B", "9B", "11B"].includes(sectionNo)) {
    return LIONS_SPECIAL_ROWS.GA_ROW;
  } else if (["Party Zone", "VIP Zone"].includes(section)) {
    return LIONS_SPECIAL_ROWS.GA_ROW;
  } else {
    return LIONS_SPECIAL_ROWS.DEFAULT_ROWS;
  }
}

const bellevillerRows = (section) => {
  const sectionNo = section.split(" ").pop();
  if (section.includes("South")) {
    return BELLEVILLE_ROWS.SOUTH_ROWS;
  } else if (section.includes("Mezzanine")) {
    return BELLEVILLE_ROWS.MEZZANINE_ROWS;
  } else if (section.includes("North")) {
    return BELLEVILLE_ROWS.NORTH_ROWS;
  } else if (section.includes("Fan Zone")) {
    return BELLEVILLE_ROWS.FAN_ZONE_ROWS;
  } else if (["101L", "107L", "111L", "117L", "301", "302", "304", "304", "305", "306", "307", "308", "309", "310", "311", "312", "313", "314", "315", "316", "317", "318", "319"].includes(sectionNo)) {
    return BELLEVILLE_ROWS.LOWER_ZONE_ROWS1;
  } else if (["100", "108", "109", "110"].includes(sectionNo)) {
    return BELLEVILLE_ROWS.LOWER_ZONE_ROWS2;
  } else if (["303"].includes(sectionNo)) {
    return BELLEVILLE_ROWS.LOWER_ZONE_ROWS3;
  } else if (["102", "112", "116"].includes(sectionNo)) {
    return BELLEVILLE_ROWS.LOWER_ZONE_ROWS4;
  } else if (["103", "105", "113", "115"].includes(sectionNo)) {
    return BELLEVILLE_ROWS.LOWER_ZONE_ROWS5;
  } else if (["106"].includes(sectionNo)) {
    return BELLEVILLE_ROWS.LOWER_ZONE_ROWS6;
  } else if (["114", "104"].includes(sectionNo)) {
    return BELLEVILLE_ROWS.LOWER_ZONE_ROWS7;
  } else if (["101", "107", "111", "117"].includes(sectionNo)) {
    return BELLEVILLE_ROWS.LOWER_ZONE_ROWS8;
  } else if (section.includes("Box")) {
    return BELLEVILLE_ROWS.LOWER_ZONE_ROWS9;
  } else {
    return BELLEVILLE_ROWS.DEFAULT_ROWS;
  }
}

const marliesRows = (section) => {
  const sectionNo = section.split(" ").pop();
  if (["103"].includes(sectionNo)) {
    return MARLIES_ROWS.LOWER_ZONE_ROWS1;
  } else if (["104", "105", "106", "107"].includes(sectionNo)) {
    return MARLIES_ROWS.LOWER_ZONE_ROWS2;
  } else if (["115", "116"].includes(sectionNo)) {
    return MARLIES_ROWS.LOWER_ZONE_ROWS3;
  } else if (["117", "114"].includes(sectionNo)) {
    return MARLIES_ROWS.LOWER_ZONE_ROWS4;
  } else if (["113"].includes(sectionNo)) {
    return MARLIES_ROWS.LOWER_ZONE_ROWS5;
  } else if (["102", "118", "101", "108", "109", "111", "112", "119"].includes(sectionNo)) {
    return MARLIES_ROWS.LOWER_ZONE_ROWS6;
  } else if (["100", "110"].includes(sectionNo)) {
    return MARLIES_ROWS.LOWER_ZONE_ROWS7;
  } else {
    return MARLIES_ROWS.DEFAULT_ROWS;
  }
}

const roughnecksRows = (section) => {
  if (section === "REST") {
    return ROUGHNECKS_ROWS.REST_ROWS;
  } else if (section === "Platinum Club") {
    return ROUGHNECKS_ROWS.PLATINUM_CLUB_ROWS;
  } else if (section.startsWith("Lower Bowl")) {
    return ROUGHNECKS_ROWS.LOWER_ZONE_ROWS;
  } else {
    return ROUGHNECKS_ROWS.DEFAULT_ROWS;
  }
}

const getRows = (slug, section) => {
  if (!section) {
    return [];
  }

  switch (slug) {
    case "calgary-hitmen":
    case "calgary-flames": {
      if (section === "REST") {
        return FLAMES_ROWS.REST_ROWS;
      } else if (section === "Platinum Club") {
        return FLAMES_ROWS.PLATINUM_CLUB_ROWS;
      } else if (section.startsWith("Lower Bowl")) {
        return FLAMES_ROWS.LOWER_ZONE_ROWS;
      } else {
        return FLAMES_ROWS.DEFAULT_ROWS;
      }
    }

    case "calgary-wranglers": {
      if (section === "REST") {
        return WRANGLERS_ROWS.REST_ROWS;
      } else if (section === "Platinum Club") {
        return WRANGLERS_ROWS.PLATINUM_CLUB_ROWS;
      } else {
        return WRANGLERS_ROWS.DEFAULT_ROWS;
      }
    }

    case "winnipeg-jets": {
      return jetsRows(section);
    }

    case 'manitoba-moose': {
      return mooseRows(section);
    }

    case "edmonton-oilers": {
      return oilersRows(section);
    }

    case "toronto-maple-leafs": {
      return mapleLeafsRows(section);
    }

    case "ottawa-senators": {
      return senatorsRows(section);
    }

    case "toronto-raptors": {
      return raptorsRows(section);
    }
    
    case "vancouver-canucks": {
      return canucksRows(section);
    }

    case "toronto-blue-jays": {
      return blueJaysRows(section);
    }

    case "edmonton-elks": {
      return elksRows(section);
    }

    case "grey-cup": 
    case "winnipeg-blue-bombers": {
      return blueBombersRows(section);
    }

    case "toronto-argonauts": {
      return argonautsRows(section);
    }
    
    case "calgary-stampeders": {
      return stampedersRows(section);
    }
    
    case "ottawa-redblacks": {
      return redblacksRows(section);
    }
    
    case "bc-lions": {
      return lionsRows(section);
    }
    
    case "saskatchewan-roughriders": {
      return roughridersRows(section);
    }
    
    case "vancouver-whitecaps-fc": {
      return whitecapsRows(section);
    }
    case "toronto-fc": {
      return torontofcsRows(section);
    }
    
    case "hamilton-tigercats": {
      return tigercatsRows(section);
    }

    case "abbotsford-canucks": {
      return ABBOTSFORD_CANUCKS_ROWS.DEFAULT_ROWS;
    }

    case "belleville-senators": {
      return bellevillerRows(section);
    }

    case "toronto-marlies": {
      return marliesRows(section);
    }

    case "calgary-roughnecks": {
      return roughnecksRows(section);
    }

    default: {
      return FLAMES_ROWS.DEFAULT_ROWS;
    }
  }
};

const getSpecialRows = (slug, section) => {
  if (!section) {
    return [];
  }

  switch (slug) {
    case "edmonton-oilers": {
      return elksRows(section);
    }
    
    case "toronto-raptors": {
      return canucksRows(section);
    }

    case "vancouver-whitecaps-fc":{
      return lionsRows(section);
    }

    case "bc-lions":{
      return lionsSpecialRows(section);
    }

    case "vancouver-canucks":{
      return ABBOTSFORD_CANUCKS_ROWS.DEFAULT_ROWS;
    }

    case "toronto-marlies":{
      return mapleLeafsRows(section);
    }

    case "belleville-senators":{
      return senatorsRows(section);
    }
    
    default: {
      return FLAMES_ROWS.DEFAULT_ROWS;
    }
  }
};

export {
  getRows,
  getSpecialRows
};
